<template>
	<div class="top_box  padding-tb-10">
		<div class="row-bg margin-lr-15 flex align-center justify-between">
			<el-col :span="4">
				<div class="flex align-center">
					<span class="point fs11 text-white" @click='toAbout'> 关于乡村人才库</span>
					<span class="padding-left-16 point fs11 text-white" @click='toFeedBack'>反馈</span>
					<!-- 空占位符 -->
					<div class="height30">
						
					</div>
				</div>
			</el-col>
			<div class="" v-if="isLogin">
				<div class="row-bg flex" justify="flex">
					<div class="text-center flex flex-direction align-center justify-center">
						<el-dropdown>
							<!-- <div class="el-dropdown-link flex align-center margin-right-20 point">
								<img src="../assets/image/icon/icon1.png" class="block-18 margin-right-5">
								<span class="fs11 padding-left-3 text-white">发布</span>
							</div> -->
							<el-dropdown-menu slot="dropdown">
								<router-link :to="{path:'/publish',query:{type:1}}">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10">
										<img src="../assets/image/home/add1.png" class="block-24 margin-right-10">
										动态
									</el-dropdown-item>
								</router-link>
								<div class="" @click="toPublish(1,'/editArticle')">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10" >
										<img src="../assets/image/home/add2.png"  class="block-24 margin-right-10">
										文章（作家权限）
									</el-dropdown-item>
								</div>
								<div class="" @click="toPublish(2,'/editBook')">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10" >
										<img src="../assets/image/home/add3.png"  class="block-24 margin-right-10">
										电子书（作家权限）
									</el-dropdown-item>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
						
					</div>
					<!-- 已登录 -->
					<div v-for="item in navList" :key="item.id" @click='goPage(item.path)' class="text-center">
						<div class="flex align-center  point padding-right-20">
							<img :src="item.img" class="block-18 margin-right-5">
							<span :class="active==item.id?'text-theme':'text-white'"
								class="fs11 padding-left-3">{{item.title}}</span>
						</div>
					</div>
					<span class="padding-right-30 padding-left-20 text-white">|</span>
					<div class="flex align-center">
						<img src="../assets/image/icon/icon6.png" class="block-18 margin-right-10 point" @click='toMsg'>
						<img src="../assets/image/ps.png" class="block-18 margin-right-10 point" @click='toexpress'>
						<div class="point block-18 margin-right-10" >
							<el-dropdown trigger="click">
								<img src="../assets/image/icon/icon7.png" class="block-18  point">
								<el-dropdown-menu slot="dropdown">
									<router-link :to="{path:'/setting',query:{type:0}}">
										<el-dropdown-item>修改密码</el-dropdown-item>
									</router-link>
									<router-link :to="{path:'/setting',query:{type:1}}">
										<el-dropdown-item>修改绑定手机</el-dropdown-item>
									</router-link>
									<el-dropdown-item >
										<div class="" @click="outLogin">
											退出登录
										</div>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="el-dropdown-link flex align-center margin-right-40 point">
							<!-- <span class="fs11 padding-left-3 text-white">发布</span> -->
							<el-dropdown trigger="click">
								<img src="../assets/image/icon/icon1.png" class="block-18 margin-right-5" style="position: relative;top:2px">
							<el-dropdown-menu slot="dropdown">
								<router-link :to="{path:'/publish',query:{type:1}}">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10">
										<img src="../assets/image/home/add1.png" class="block-24 margin-right-10">
										动态
									</el-dropdown-item>
								</router-link>
								<div class="" @click="toPublish(1,'/editArticle')">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10" >
										<img src="../assets/image/home/add2.png"  class="block-24 margin-right-10">
										文章（作家权限）
									</el-dropdown-item>
								</div>
								<div class="" @click="toPublish(2,'/editBook')">
									<el-dropdown-item class="clearfix flex align-center padding-tb-10" >
										<img src="../assets/image/home/add3.png"  class="block-24 margin-right-10">
										电子书（作家权限）
									</el-dropdown-item>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
						</div>
					</div>
				</div>
			</div>
			<!-- 未登录 -->
			<div class="margin-right-200" v-else>	
				<router-link to="/login">
					<el-button type="danger" size="medium">登录</el-button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'top-custom',
		props: {
			active: Number,
			bgWhite: [String, Boolean]
		},
		computed:{
			// 是否登录状态
			isLogin() {
			  return this.$store.getters.isLogin;
			},
			// 是否是作家
			is_writer() {
			  return this.$store.getters.is_writer;
			}
		},
		data() {
			return {
				popverRef: true, //
				navList: [{
						id: 2,
						title: '电子书制作',
						img: require('../assets/image/icon/icon2.png'),
						path: '/editBook'
					},
					{
						id: 3,
						title: '拼书',
						img: require('../assets/image/icon/icon3.png'),
						path: '/spell-book'
					},
						{
						id: 4,
						title: '个人出书',
						img: require('../assets/image/icon/icon3.png'),
						path: '/about?type=29'
					},
					{
						id: 5,
						title: '首页',
						img: require('../assets/image/icon/icon3.png'),
						path: '/'
					},
					// {
					// 	id: 6,
					// 	title: '热榜',
					// 	img: require('../assets/image/icon/icon3.png'),
					// 	path: '/rank-list'
					// },
					{
						id: 7,
						title: '人才',
						img: require('../assets/image/icon/icon4.png'),
						path: '/talent_index'
					},
					{
						id: 8,
						title: '我的',
						img: require('../assets/image/icon/icon5.png'),
						path: '/my'
					},
				],
				userInfo: {}
			}
		},
		mounted() {
			this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
		},
		methods: {
			// 去发布
			async toPublish(type,path){
				let auditInfo = await this.getAuditInfo();
				console.log(auditInfo.status);
				// 0  审核中
				// 1  审核通过
				// 2  审核拒绝
				// 3  待支付
				let info  = type==1 ? '文章': '电子书';
				switch (auditInfo.status){
					case -99:
						this.$confirm(`只有作家才可以发布${info}哦，去认证?`, '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 0:
						this.$confirm('认证审核中，请耐心等待', '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  
						}).catch(() => {
						         
						});
						break;
					case 1:
						this.$router.push({
							path:path,
							query:{
								ducoment_type:2
							}
						})
						break;
					case 2:
						let reasons = auditInfo.reason || '暂无原因';
						this.$confirm('认证审核未通过，拒绝原因为：'+reasons +'，请重新提交', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 3:
						this.$confirm('认证未支付，去支付？', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.addOrder(auditInfo.period_id,auditInfo.id)
						}).catch(() => {
						         
						});
						break;
					default:
						break;
				}
			},
			// 获取认证信息
			getAuditInfo() {
				return new Promise((resolve,reject)=>{
					this.ajax('post', '/v1/6142e36210b86', {}, res => {
						resolve(res.data)
					})
				})
			},
			// 统一下单接口
			addOrder(period_id,certified_id){
				this.ajax('post', '/v1/5d784b976769e', {
					order_type:4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
					period_id:period_id,
					certified_id:certified_id
				}, res => {
					if (res.code == 1) {
						let order_sn = res.data.order_sn;
						this.$router.push({
							path: '/payMoney',
							query:{
								order_sn:res.data.order_sn,
								order_money:res.data.money,
								return_url:JSON.stringify(window.location.href)
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取个人信息
			getUserInfo() {
				let userinfo = JSON.parse(localStorage.getItem('USER_INFO'))
				return new Promise((resolve,reject)=>{
					this.ajax('post', '/v1/5c78c4772da97', {
						user_id:userinfo.id || ''
					}, res => {
						if (res.code == 1) {
							resolve(res.data.is_writer)
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			async goPage(url) {
				let auditInfo = await this.getAuditInfo();
				if(url=="/editBook"){
					switch (auditInfo.status){
						case -99:
							this.$confirm(`只有作家才可以制作电子书哦，去认证?`, '提示', {
							  confirmButtonText: '好的',
							  type: 'info'
							}).then(() => {
							  this.$router.push('/authen')
							}).catch(() => {
							         
							});
							break;
						case 0:
							this.$confirm('认证审核中，请耐心等待', '提示', {
							  confirmButtonText: '好的',
							  type: 'info'
							}).then(() => {
							  
							}).catch(() => {
							         
							});
							break;
						case 1:
							this.$router.push({
								path:url,
								query:{
									ducoment_type:2
								}
							})
							break;
						case 2:
							let reasons = auditInfo.reason || '暂无原因';
							this.$confirm('认证审核未通过，拒绝原因为：'+reasons +'，请重新提交', '提示', {
							  confirmButtonText: '好的',
							  cancelButtonText: '不了',
							  type: 'info'
							}).then(() => {
							  this.$router.push('/authen')
							}).catch(() => {
							         
							});
							break;
						case 3:
							this.$confirm('认证未支付，去支付？', '提示', {
							  confirmButtonText: '好的',
							  cancelButtonText: '不了',
							  type: 'info'
							}).then(() => {
							  this.addOrder(auditInfo.period_id,auditInfo.id)
							}).catch(() => {
							         
							});
							break;
						default:
							break;
					}
				}else{
					this.$router.push(url)
				}
			},
			outLogin() {
				this.$confirm('确认退出登录?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.$store.commit('logout');
				  this.$router.replace({
				  	path:'/login'
				  })
				}).catch(() => {
				           
				});
			},
			toMsg() {
				this.$router.push('/system-msg')
			},
			toexpress(){
					this.$router.push('/express-list')
			},
			toSet() {
				this.$router.push('/setting')
			},
			toFeedBack() {
				this.$router.push('/feedback')
			},
			toAbout() {
				this.$router.push({
					path:'/about',
					query:{
						type:25
					}
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.top_box {
		/* height: 44px; */
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 1000;
		background: url(../assets/image/home/top-bg.png) no-repeat;
		background-size: cover;

		/* background-color: #F8F8F8; */
		/* line-height: 44px; */
	}

	.nav_item {
		line-height: 40px;
	}

	.main-width {
		margin: auto;
	}

	.flex {
		align-items: center;
	}

	.right {
		border: 1px solid #666666;
		padding: 6px 10px;
		font-size: 14px;
	}

	.right_icon {
		margin-left: 10px;
		border-left: 1px solid #666666;
		padding-left: 10px;
	}

	.logo {
		width: 40px;
		height: 40px;
		margin-top: 10px;
	}
</style>
