<template>
	<div class="page_wrap">
		<top-custom ></top-custom>
		<div class="home  fs30" id="target_top">
			<div class="header flex align-center justify-center">
				<div class="">
					<div class="search flex align-center">
						<img src="../../../assets/image/logo_white.png" class="logo-img margin-bottom-40">
						<!-- 热搜 -->
						<div class="flex flex-direction  justify-center margin-left-40">
							<!-- 搜索框 -->
							<div class="input-box flex align-center justify-between">
								<input type="" name="" id="" value="" v-model="keyword" @keyup.enter="handleSearch" />
								<div class="search-box flex align-center justify-center point" @click="handleSearch">
									<span class="el-icon-search text-white fs16"></span>
								</div>
							</div>
							<div class="hot-search margin-top flex align-center">
								<img src="../../../assets/image/home/hot.png" class="hot-img margin-right-10">
								<div class="flex align-center">
									<span class="fs11 text-white">热搜：</span>
									<div class="flex">
										<p v-for="(item,index) in hotSearch" @click="hotSearchClick(item)" :key="index" class="text-white fs11 margin-right-10 point">{{item.name}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 顶部end -->

			<!-- 主体内容 start -->
			<div class="flex contain margin-top justify-around">
				<el-row :gutter="24" class="w100">
					<el-col :span="4" class="tab_box">
						<!-- 左侧tab切换 -->
						<div class="flex flex-direction align-center ">
							<button type="button" class="margin-bottom-xs point fs16"
								:class="tabIndex==index ? 'tab-active' : 'tab-normal' " v-for="(item,index) in tabLists"
								:key="index" @click="tabChange(item,index)">{{item.name}}</button>
						</div>
					</el-col>
					<el-col :span="15">
						<!-- 中间内容 -->
						<div class="middle-box padding-lr-20">
							<p class="title">推荐</p>
							<!-- 加载中 -->
							<div class="w100" v-if="!isInit">
								<el-skeleton :rows="12" animated />
							</div>
							<!-- 加载有数据 -->
							<div class="w100" v-else>
								<div v-if="lists.length>0">
									<!-- 动态、文章 -->
									<div class="" v-if="tabIndex==0 || tabIndex==1 || tabIndex==3">
										<div  v-for="(item,index) in lists" :key="index">
											<dynamic  :is_remind='true'  :current='tabIndex+1' :item="item" @addFocus="getMyFocus"></dynamic>
										</div>
									</div>
									<!--电子书 -->
									<el-row :gutter="24" v-if="tabIndex==2">
										<el-col :span="12" v-for="(item,index) in lists" :key="index">
											<div class="grid-content bg-purple">
												<book-list :item="item"></book-list>
											</div>
										</el-col>
									</el-row>
								</div>
								<div class="" v-if="lists.length==0">
									<el-empty description="~空空如也~"></el-empty>
								</div>
								<div class="flex align-center  margin-top-30">
									 <el-pagination
										  background
									      @size-change="handleSizeChange"
									      @current-change="handleCurrentChange"
									      layout="prev, pager, next"
										  :page-size="15"
									      :total="totalSize">
									    </el-pagination>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="5">
						<!-- 右侧信息 -->
						<div class="">
							<!-- 关注列表 -->
							<div class="focus-box" v-if="isLogin">
								<div class="flex align-center">
									<img src="../../../assets/image/home/follow.png" class="title-img margin-right-5">
									<span class="title">关注</span>
								</div>
								<div class="focus-list margin-top-sm" v-for="(foucs_item,focus_index) in focusLists" :key="focus_index">
									<div class="flex align-center focus-item margin-bottom-xs point" @click="toDetail(foucs_item)">
										<el-image :src="foucs_item.head_img" class="head-img margin-right-xs  radius-28" fit="cover"></el-image>
										<span class="name fs16">{{foucs_item.user_nickname}}</span>
									</div>
								</div>
								<div class="check-box point padding-right-60">
									<router-link to="/myFocus">
										<span class="fs14">查看详情</span>
										<i class="el-icon-arrow-right"></i>
									</router-link>
								</div>
							</div>
							<!-- 热榜 -->
							<div class="hot-box">
								<div class="flex align-center justify-between">
									<div class="flex align-center">
										<img src="../../../assets/image/home/hot.png" class="title-img margin-right-5">
										<span class="title">热榜</span>
									</div>
									<div class="flex align-center point"  @click="changeData">
										<img src="../../../assets/image/home/update_red.png" :class="showRotate ? 'loading' : '' "  class="block-24 margin-right-5">
										<span class="change-title">换一换</span>
									</div>
								</div>
								<div class="hot-list margin-top-50">
										<div class="hot-item point margin-bottom-30" v-for="(hot_item,hot_index) in hotLists" :key="hot_index">
											<div class="flex" @click="toArticleDetail(hot_item.aid)">
												<span class="hot-index margin-right-xs" :class="hot_index<3 ? 'text-theme' : 'text-666' ">{{hot_index+1}}</span>
												<span class="hot-title text-cut">{{hot_item.title ||'暂无标题' }}</span>
											</div>
										</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- 主体内容 end -->
			<!-- 悬浮按钮 -->
			<div class="float-box flex flex-direction align-center justify-around" v-show="scrollTop>500">
				<img src="../../../assets/image/home/top.png" class="block-28" @click="scrollToTop">
				<!-- <img src="../../../assets/image/home/add.png" class="block-19"> -->
				<i class="el-icon-circle-plus-outline fs28" :class="dialogTableVisible?'text-e54f':'text-333'" @click="dialogTableVisible=true"></i>
				<img src="../../../assets/image/home/uptade_black.png" class="block-28 point" @click="update" :class="showRotate2 ? 'loading' : '' ">

			</div>
			<div class="mask" @click="dialogTableVisible=false" v-if="dialogTableVisible"></div>
			<div class="box-content bg-white radius-3  radius-10" v-if="dialogTableVisible">
				<div class="" style="position: relative;">
					<router-link to="/publish">
						<div class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point" >
							<img src="../../../assets/image/home/add1.png" class="block-24">
							<span class="padding-left-10 fs14">动态</span>
						</div>
					</router-link>
					<div class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point" @click="toPublish(1,'/editArticle')">
						<img src="../../../assets/image/home/add2.png" class="block-24">
						<span class="padding-left-10 fs14">文章（作家权限）</span>
					</div>
					<div class="flex align-center padding-tb-20 padding-lr-30 point" @click="toPublish(2,'/editBook')">
						<img src="../../../assets/image/home/add3.png" class="block-24" >
						<span class="padding-left-10 fs14">电子书（作家权限）</span>
					</div>
					<div class="bg-f6f7 height10 w270">	</div>
				</div>
				<div class="padding-tb-15 text-center fs14 text-e54f" @click="dialogTableVisible=false">
					取消
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import dynamic from '@/components/dynamic/dynamic';
	import bookList from '@/components/book-list/book-list';
	import topCustom from '@/components/top-custom';
	import bottom from '@/components/bottom';
	import loadMore from '@/components/load-more/load-more.vue';
	export default {
		components: {
			topCustom,
			bottom,
			dynamic,
			bookList,
			loadMore
		},
		name: 'Home',
		data() {
			return {
				keyword:'', //搜索关键词
				status:'more',//热榜是否有更多
				showRotate:false, //是否旋转
				showRotate2:false, //是否旋转
				hotLists:[],//热榜
				focusLists:[], //关注列表
				isInit:false,
				page:1,
				totalSize:0,//总页数
				loading:false,
				iscompleted:false, //当设置为 true 时将不会再触发 infinite-scroll 事件.当所有数据全部加载完毕后，建议设置为 true
				scrollTop: 0,
				dialogTableVisible: false, //新建弹框
				tabIndex: 0,
				hotSearch: [{
						name: '学生开学了'
					},
					{
						name: '三毛'
					}
				],
				tabLists: [{
						id: 1,
						name: '动态'
					},
					{
						id: 2,
						name: '文章'
					},
					{
						id: 3,
						name: '电子书'
					},
					{
						id: 4,
						name: '付费专栏'
					},
					{
						id: 5,
						name: '热榜'
					}
				],
				lists: []
			}
		},
		computed: {
		  // 是否登录状态
		  isLogin() {
		    return this.$store.getters.isLogin;
		  },
		  // 是否是作家
		  is_writer() {
		    return this.$store.getters.is_writer;
		  }
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			this.loadData();
			this.getHotLists()//获取热榜数据
			if(this.isLogin){
				this.getMyFocus() //获取我的关注
				this.gethotsearch() //获取热搜关键词
			};
			window['getSonData'] = ()=>{
				this.loadData();
			};
			this.getWeChatToken();
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			/* 重定向后，获取openId */
			async getWeChatToken() {
				if (this.$router.history.current.path == '/' && this.$route.query.code) {
					console.log('授权登录回来的');
					// let code = "051H81200cl6QM1IP83008exKN2H812V&state=STATE"
					let code = this.$route.query.code
					// console.log('路由', code);
					// 通过code获取access_token
			
					this.codeGetOpenid(code);
					return;
				} else {
					// console.log('进入首页');
				}
			},
			/* 通过code获取openid */
			codeGetOpenid(code) {
				this.ajax('post', '/v1/619714950d880',{
					code:code
				}, res => {
					if (res.code==1) {
						localStorage.setItem('USER_INFO', JSON.stringify(res.data.userinfo))
						localStorage.setItem('user_token', res.data.userinfo.user_token)
						this.$store.commit('updateUserInfo', res.data.userinfo);
						// window.location.reload();  //刷新页面
					} else if(res.code==-999 && res.data.result){
						this.$router.push({
							path: '/bind_phone',
							query:{
								openid:res.data.openid,
								unionid:res.data.result.unionid,
								nickName:res.data.result.nickname,
								avatarUrl:res.data.result.headimgurl
							}
						})
					}
				}, err => {
					console.log('微信登录code获取失败', err);
				})
			},
			// 去发布
			async toPublish(type,path){
				let auditInfo = await this.getAuditInfo();
				// 0  审核中
				// 1  审核通过
				// 2  审核拒绝
				// 3  待支付
				let info  = type==1 ? '文章': '电子书';
				switch (auditInfo.status){
					case -99:
						this.$confirm(`只有作家才可以发布${info}哦，去认证?`, '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 0:
						this.$confirm('认证审核中，请耐心等待', '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  
						}).catch(() => {
						         
						});
						break;
					case 1:
						this.$router.push({
							path:path,
							query:{
								ducoment_type:2
							}
						})
						break;
					case 2:
						let reasons = auditInfo.reason || '暂无原因';
						this.$confirm('认证审核未通过，拒绝原因为：'+reasons +'，请重新提交', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 3:
						this.$confirm('认证未支付，去支付？', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.addOrder(auditInfo.period_id,auditInfo.id)
						}).catch(() => {
						         
						});
						break;
					default:
						break;
				}
			},
			// 获取认证信息
			getAuditInfo() {
				return new Promise((resolve,reject)=>{
					this.ajax('post', '/v1/6142e36210b86', {}, res => {
						if(res.code==-201){
							this.$router.push('/login')
						}else{
							resolve(res.data)
						}
					})
				})
			},
			// 统一下单接口
			addOrder(period_id,certified_id){
				this.ajax('post', '/v1/5d784b976769e', {
					order_type:4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
					period_id:period_id,
					certified_id:certified_id
				}, res => {
					if (res.code == 1) {
						let order_sn = res.data.order_sn;
						this.$router.push({
							path: '/payMoney',
							query:{
								order_sn:res.data.order_sn,
								order_money:res.data.money,
								return_url:JSON.stringify(window.location.href)
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 动态更新
			update(){
				this.showRotate2 = true;
				setTimeout(()=>{
					this.showRotate2 = false
				},400);
				this.page = 1;
				this.loadData();
				setTimeout(()=>{
					target_top.scrollIntoView();
				}, 400);
			},
			// 搜索
			handleSearch(){
				this.isInit = false;
				this.page = 1;
				this.loadData()
			},
			//获取热搜
			gethotsearch(){
				this.ajax('post', '/v1/645a1ccf29f17', {}, res => {
						if(res.code==-201){
							this.$router.push('/login')
						}else{
						this.hotSearch = res.data
						}
					})
			},
			// 热搜点击
			hotSearchClick(item){
				this.keyword = item.name;
				this.isInit = false;
				this.page = 1;
				this.loadData()
			},
			// 换一换
			changeData(){
				this.showRotate = true;
				setTimeout(()=>{
					this.showRotate = false
				},400);
				this.getHotLists()
			},
			// 页数改变
			handleSizeChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
			// 跳页
			handleCurrentChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
			handleScroll(e) {
				this.scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
			},
			// 发布动态
			publish(){
				this.$router.push('/publishDynac')
			},
			getNews() {
				this.ajax('post', '/common/newsList', {
					cate_id: this.calatId
				}, res => {
					if (res.code == 1) {

						this.newsList = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// tab切换
			tabChange(item, index) {
				if (this.tabIndex == index) return;
				this.tabIndex = index;
				console.log(index,'index')
				if(index != 4){
					console.log(111111)
				this.isInit = false;
				this.page = 1;
				this.loadData();
				target_top.scrollIntoView();
				}else{
					this.$router.push('/rank-list')
				}
			
			},
			// 显示按钮的显示与隐藏
			scrollToTop() {
				let scrollToptimer = setInterval(function() {
					// console.log("定时循环回到顶部");
					var top = document.body.scrollTop || document.documentElement.scrollTop;
					var speed = top / 4;
					document.documentElement.scrollTop -= speed;
					if (top == 0) {
						clearInterval(scrollToptimer);
					}
				}, 30);
			},
			// 推荐数据加载
			loadData() {
				this.ajax('post', '/v1/613b0f6c07cc9', {
					article_type:this.tabIndex+1, //1：动态2：文章3：电子书
					index_type:2 ,//1：关注2：推荐3：热榜
					page:this.page,
					keyword:this.keyword
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 跳转人才详情
			toDetail(item){
				this.toBlank({
					path:'/talents_detail',
					query:{
						user_id:item.id
					}
				})
			},
			// 跳转文章详情
			toArticleDetail(aid){
				this.toBlank({
					path:'/artileDetail',
					query:{
						article_id:aid,
						atype:2
					}
				})
			},
			// 获取我的关注
			getMyFocus() {
				this.ajax('post', '/v1/613c025b9436d', {
					type:2, //1粉丝2关注
					page:1
				}, res => {
					if (res.code == 1) {
						this.focusLists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 热榜数据加载
			getHotLists() {
				// if (this.status != "more") return;
				this.ajax('post', '/v1/613b0f6c07cc9', {
					article_type:2, //1：动态2：文章3：电子书
					index_type:3 ,//1：关注2：推荐3：热榜
					page:this.page
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.hotLists =  res.data.data.splice(0,10);
						if (this.page < res.data.last_page) {
						  this.status = "more";
						  this.page++;
						} else {
						  this.page = 1;
						  this.status = "noMore";
						}
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			}

		}
	}
</script>
<style lang="scss" scoped="scoped">
	@import url("./home.css");
	.loading { 
	     -webkit-animation: rotation 0.4s linear; 
	} 
	@-webkit-keyframes rotation { 
	     from { 
	       -webkit-transform: rotate(0deg); 
	     } 
	     to { 
	       -webkit-transform: rotate(180deg); 
	     } 
	}
	   ::-webkit-scrollbar {
	     width: 0 !important;
	   }
	   ::-webkit-scrollbar {
	     width: 0 !important;height: 0;
	   }

	.tab_box{
		position: sticky;
		top: 80px;
	}
	.middle-box {
		padding-bottom: 80px;
	}
	.input-box{
		width: 686px;
		height: 48px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 24px;
		input{
			width: 80%;
			height: 100%;
			background: #FFFFFF;
			padding: 0 20px;
			border-radius: 24px 0 0 24px;
		}
	}
	/* 右侧信息 */
	.title-img {
		width: 28px;
		height: 28px;
	}

	.title {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.change-img {
		width: 16px;
		height: 16px;
	}
	.box-content {
		position: fixed;
		width: 270px;
		right: 315px;
		bottom: 93px;
		z-index: 1510;
		background-color: #FFFFFF;
	}

	.change-title {
		font-size: 9px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 15px;
		color: #E54F42;
		opacity: 1;
	}

	.hot-box {
		margin-top: 100px;
	}

	.hot-list {
		.hot-item {
			.hot-index {
				font-size: 14px;
				font-family: OPPOSans;
				font-weight: bold;
				line-height: 16px;
				opacity: 1;
			}
			.text-theme{
				color: #FE2D46;
			}
			.hot-title {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 14px;
				color: #333333;
				opacity: 1;
			}
		}
	}

	.focus-box {
		.check-box {
			height: 15px;
			font-size: 11px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 15px;
			color: #098EF0;
			opacity: 1;
			text-align: right;
			margin-top: 20px;
		}

		.focus-list {
			.focus-item {
				.head-img {
					width: 24px;
					height: 24px;
				}

				.name {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					opacity: 1;
				}
			}

		}
	}

	.float-box {
		position: fixed;
		right: 245px;
		bottom: 138px;
		width: 72px;
		height: 246px;
		background: #FFFFFF;
		box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
		opacity: 1;
		border-radius: 43px;
	}
</style>
